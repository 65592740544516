import "./DownloadBtn.scss";
import { AiOutlineDownload } from "react-icons/ai";

const DownloadBtn = ({link, children}) => {
    return (
        <a 
            className="download-btn"
            href={link}
            target="_blank"
        >
            {children} 
            <div className="download-btn__btn">
                <AiOutlineDownload /> 
                Скачать
            </div>
        </a>
    );
}
 
export default DownloadBtn;