import "./App.scss";
import DownloadBtn from "./components/DownloadBtn/DownloadBtn";
import InfoCard from "./components/InfoCard/InfoCard";
import InfoPiece from "./components/InfoPiece/InfoPiece";
import file from "./donwloadables/Программа_ДО.pdf";

const App = () => {
    return (
        <div className="main-content-wrapper">
            <main className="main-content">
                <header className="header">
                    <h1 className="header__heading">ИП Жидких Ольга Андреевна</h1>
                </header>
                {/* First row */}
                <div className="columns">
                    <div className="columns__left">
                        <InfoCard heading="Реквизиты">
                            <InfoPiece heading="ИНН">
                                713602398340
                            </InfoPiece>
                            <InfoPiece heading="ОГРНИП">
                                323774600374271
                            </InfoPiece>
                        </InfoCard>
                    </div>
                    <div className="columns__right">
                        <InfoCard heading="Контакты">
                            <InfoPiece heading="Телефон">
                                <a href="tel:+7916920130">+7 (916) 292-01-30</a>
                            </InfoPiece>
                            <InfoPiece heading="Электронная почта">
                                <a href="mailto:o.vishnyakova@bk.ru">o.vishnyakova@bk.ru</a>
                            </InfoPiece>
                        </InfoCard>
                    </div>
                </div>
                {/* Second row */}
                <div className="row">
                    <InfoCard heading="Адреса">
                        <InfoPiece heading="Фактический адрес осуществления деятельности">
                            109316, г. Москва, ул. Мельникова д. 3 корп. 5, этаж 1, помещение № VI, комната № 1
                        </InfoPiece>
                    </InfoCard>
                </div>
                {/* Third row */}
                <div className="row">
                    <InfoCard heading="Реализуемые образовательные программы">
                        <DownloadBtn link={file}>
                            Дополнительная общеобразовательная программа – Дополнительная общеразвивающая программа
                        </DownloadBtn>
                    </InfoCard>
                </div>
            </main>
        </div>
    );
}
 
export default App;
