import "./InfoPiece.scss";

const InfoPiece = ({heading, children}) => {
    return (
        <div className="info-piece">
            <h3 className="info-piece__heading">
                {heading}
            </h3>
            <div className="info-piece__text">
                {children}
            </div>
        </div>
    );
}
 
export default InfoPiece;