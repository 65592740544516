import "./InfoCard.scss";

const InfoCard = ({heading, children}) => {
    return (
        <section className="info-card">
            <h2 className="info-card__heading">
                {heading}
            </h2>
            <p className="info-card__body">
                {children}
            </p>
        </section>
    );
}
 
export default InfoCard;